if (typeof segmentData != "undefined") {
    !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
            if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
            else {
                analytics.invoked = !0;
                analytics.methods = [
                    "trackSubmit",
                    "trackClick",
                    "trackLink",
                    "trackForm",
                    "pageview",
                    "identify",
                    "reset",
                    "group",
                    "track",
                    "ready",
                    "alias",
                    "debug",
                    "page",
                    "once",
                    "off",
                    "on",
                    "addSourceMiddleware",
                    "addIntegrationMiddleware",
                    "setAnonymousId",
                    "addDestinationMiddleware",
                ];
                analytics.factory = function (e) {
                    return function () {
                        var t = Array.prototype.slice.call(arguments);
                        t.unshift(e);
                        analytics.push(t);
                        return analytics;
                    };
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e];
                    analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, e) {
                    var t = document.createElement("script");
                    t.type = "text/javascript";
                    t.async = !0;
                    t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
                    var n = document.getElementsByTagName("script")[0];
                    n.parentNode.insertBefore(t, n);
                    analytics._loadOptions = e;
                };
                analytics._writeKey = segmentData.clientKey;
                analytics.SNIPPET_VERSION = "4.13.2";
                analytics.load(segmentData.clientKey);
            }
    })();

    // If the Segment analytics object exists and if analytics.user is a function.
    // Note: analytics.user may not exist if an ad-blocker is being used
    analytics.ready(function () {
        var webUserId = segmentData.customerId;
        var userSignedIn = segmentData.userSignedIn === "true";

        var segmentAnonymousId = getSegmentAnonymousId();

        if (segmentAnonymousId == null) {
            // if the segment_anonymous_id is null then set it equal to web_user_id
            segmentAnonymousId = webUserId;

            setSegmentAnonymousId(segmentAnonymousId);
        }

        if (analytics && typeof analytics.user == "function") {
            // Set the Segment anonymousId to have the same value as the segment_anonymous_id cookie
            analytics.user().anonymousId(segmentAnonymousId);

            if (userSignedIn && webUserId) {
                analytics.user().id(webUserId);
            } else {
                analytics.user().id(null);
            }
        }

        segmentPageController.init(window.location.href);

        if (!!segmentData.zitchaIntegrationEnabled) {
            analytics.addSourceMiddleware(({ payload, next }) => {
                payload.obj.context.features = payload.obj.context.features || {};
                payload.obj.context.features.zitcha = true;

                next(payload);
            });

            analytics.register(zitchaIntegration());
            analytics.isZitchRegistered = true;
        }

        analytics.page(
            document.title,
            segmentPageController.getPageProperties(),
            segmentPageController.getPageOptions(),
        );

        function getSegmentAnonymousId() {
            var cookieName = "segment_anonymous_id";
            var cookieArray = document.cookie.split(";");

            for (var i = 0; i < cookieArray.length; i++) {
                var cookiePair = cookieArray[i].trim();

                if (cookiePair.indexOf(cookieName) === 0) {
                    return cookiePair.substring(cookieName.length + 1, cookiePair.length);
                }
            }

            return null;
        }

        function setSegmentAnonymousId(segmentAnonymousId) {
            var expiryDate = new Date();
            var numberOfDays = 90;
            expiryDate.setTime(expiryDate.getTime() + numberOfDays * 24 * 60 * 60 * 1000);

            // Note: this cookie needs to last for as long as possible
            document.cookie = "segment_anonymous_id=" + segmentAnonymousId + "; expires=" + expiryDate.toUTCString();
        }
    });
}
